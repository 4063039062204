import * as React from "react"
import Header from "./Header";
import Footer from "./Footer";
import {Helmet} from "react-helmet";
import Context, { ContextProvider, ContextConsumer } from "./Context";
import {useContext, useState} from "react";
import Overlay from "./Overlay";
import ContactForm from "./ContactForm";

const Layout = ({location, path, children}) => {
    const context = useContext(Context);

    const [supportVisible, setSupportVisible] = useState(false);
    const [teamVisible, setTeamVisible] = useState(false);

    const {href} = location;

    const menu = [
        {
            label: 'License Request',
            href: path === '/' ? "#license-request" : "/#license-request",
            useLinkComponent: true,
            current: href && href.includes('#license-request'),
            submenuItems: null,
        },
        {
            label: 'Download',
            href: path === '/' ? "#sysreq" : "/#sysreq",
            useLinkComponent: true,
            current: href && href.includes('#sysreq'),
            submenuItems: null,
        },
        {
            label: 'Pricing',
            href: path === '/' ? "#pricing" : "/#pricing",
            useLinkComponent: true,
            current: href && href.includes('#pricing'),
            submenuItems: null,
        },
        {
            label: 'Support',
            href: "#",
            useLinkComponent: false,
            current: href && href.includes('/blog'),
            submenuItems: [
                {
                    label: 'Blog',
                    sublabel: 'Updates and articles',
                    useLinkComponent: true,
                    href: '/blog'
                },
                {
                    label: 'User guide',
                    sublabel: 'Read our documentation',
                    useLinkComponent: false,
                    href: 'https://dailyciousapp.notion.site/Dailycious-User-Guide-dc1557366bdc4abb94bc5386c89b5423'
                },
                {
                    label: 'Contact support',
                    sublabel: 'Let\'s talk',
                    useLinkComponent: true,
                    href: '#',
                    onClick: () => {
                        setSupportVisible(true);
                    }
                }
            ],
        },
        {
            label: 'Contact',
            href: "#",
            useLinkComponent: true,
            current: false,
            submenuItems: null,
            onClick: () => {
                setTeamVisible(true);
            }
        }
    ];

    return (
        <ContextProvider value={context}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dailycious — Watch your dailies</title>
            </Helmet>
            <Header location={location} path={path} menu={menu} />
            <main>
                {children}
            </main>


            {supportVisible ?
                <Overlay title={"Contact Support"} onCloseHandler={() => setSupportVisible(false)}>
                    <div className="overlay__subtitle">
                        Any questions, any suggestions? let us know!
                    </div>
                    <ContactForm/>
                </Overlay>
                :
                null
            }

            {teamVisible ?
                <Overlay title={"Contact"} onCloseHandler={() => setTeamVisible(false)}>
                    <div className="team-container">
                        <div className="team-section">
                            Noir Lumière
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="team">
                                    <div className="team__name">Tommaso Vergallo <span className="team__role">CEO</span></div>
                                    <div className="team__email"><a href="mailto:tommaso@noirlumiere.com">tommaso@noirlumiere.com</a></div>
                                    <div className="team__phone">+33 6 25 35 23 35</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="team">
                                    <div className="team__name">Etienne Traisnel <span className="team__role">Président</span></div>
                                    <div className="team__email"><a href="mailto:etienne@noirlumiere.com">etienne@noirlumiere.com</a></div>
                                    <div className="team__phone">+33 6 63 30 04 03</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="team">
                                    <div className="team__name">François Dupuy <span className="team__role">CTO</span></div>
                                    <div className="team__email"><a href="mailto:francois@noirlumiere.com">francois@noirlumiere.com</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="team-container">
                        <div className="team-section">
                            Be4Post
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="team">
                                    <div className="team__name">Brice Barbier</div>
                                    <div className="team__email"><a href="mailto:brice@be4post.com">brice@be4post.com</a></div>
                                    <div className="team__phone">+33 6 30 26 35 52</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="team">
                                    <div className="team__name">Guillaume Poirson <span className="team__role">CTO</span></div>
                                    <div className="team__email"><a href="mailto:tommaso@noirlumiere.com">rental@be4post.com</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Overlay>
                : null}



            <Footer/>
        </ContextProvider>
    )
}

export default Layout
