import * as React from "react"
import {useState} from "react";

const ConfirmationMessage = () => (
    <div className={"message message--success"}>
        Your message has been sent successfully.
    </div>
)

const ContactForm = () => {

    const [formSent, setFormSent] = useState(false);

    const handleFormSubmit = (e) => {
        e.preventDefault()
        const form = document.getElementById('contact-form');
        const formData = new FormData(form);
        fetch('/', {
            method: 'POST',
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams(formData).toString()
        }).then(() => {
            setFormSent(true);
            console.log('Form successfully submitted');
        }).catch((error) =>
            alert(error))
    }

    return (
        <>
            {formSent ? <ConfirmationMessage/> : null }
            {!formSent ? <form id="contact-form" name="contact-be4post" method="POST" data-netlify="true" onSubmit={handleFormSubmit}>
                <input type="hidden" name="form-name" value="contact-be4post" />
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="support-first-name">First Name</label>
                            <input type="text" name="first_name" className="form-control" id="support-first-name" required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="support-last-name">Last Name</label>
                            <input type="text" name="last_name" className="form-control" id="support-last-name" required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="support-phone">Phone</label>
                            <input type="text" name="phone" className="form-control" id="support-phone" required />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="support-email">Email address</label>
                            <input type="email" name="email" className="form-control" id="support-email" required />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="support-company-production">Company / Production</label>
                            <input type="text" name="company-production" className="form-control" id="support-company-production" required />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="support-subject">Subject</label>
                            <input type="text" name="subject" className="form-control" id="support-subject" required />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="support-message">Message</label>
                            <textarea name="message" className="form-control" id="support-message" required />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-submit-container">
                            <div>
                                <button type="submit" className="button-1">Envoyer</button>
                            </div>
                            <div>
                                <p>
                                    By clicking on "Send message", I agree to be contacted by the Dailycious teams and that the data filled in this form be used for update notifications by email.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </form> : null }
        </>
    )
}

export default ContactForm;
