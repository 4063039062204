import * as React from "react";

const LicenseRequest = () => {
    return (
        <div className="license-request">
            <div className="license-request__title title-2">
                License Request
            </div>
            <div className="license-request__box text-1">
                If you have received a Dailycious identification key by e-mail,<br/>you need to activate your access in the player.
            </div>
            <div className="license-request__actions">
                <a href="https://api.dailycious.io/get-one" target="blank" rel="nofollow" className="button-1">Get a license</a>
            </div>
        </div>
    )
}

export default LicenseRequest;


