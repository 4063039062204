import * as React from "react"
import classNames from "classnames";
import IconClose from "../assets/icon-close.svg";

const Overlay = ({children, title, onCloseHandler}) => {

    const onClickHandler = (e) => {
        e.stopPropagation();
        onCloseHandler()
    }

    return (
        <div className={classNames('overlay', {'overlay--visible': true})} onClick={onClickHandler}>
            <div className="overlay__content" onClick={(e) => e.stopPropagation()}>

                <div className="overlay__close">
                    <IconClose onClick={onClickHandler} />
                </div>

                {title ?
                    <div className="overlay__title">
                        {title}
                    </div>
                    : null}
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Overlay
