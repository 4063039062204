import DailyciousMonogram from "../assets/dailycious-monogram.svg";
import * as React from "react";
import LicenseRequest from "./LicenseRequest";

const Footer = () => {
    return (
        <footer className="main-footer">
            <div id="license-request">
                <LicenseRequest/>
            </div>
            <div className="main-footer-separator" />
            <DailyciousMonogram/>
            <div className="main-footer__sosumi">
                © 2021 DAILYCIOUS • Dailycious is developed jointly and proudly by <a href="https://noirlumiere.com/" target="_blank" rel="noreferrer" >Noir Lumière</a> and <a href="http://www.be4post.com/" target="blank" rel="noreferrer">Be4Post</a>.
            </div>
        </footer>
    )
}

export default Footer;
